<template>
  <div class="check_registration">
    <b-container v-if="registration">
      <!-- Reserv -->
      <b-row align-h="center" class="check_registration__block">
        <b-col cols="12">
          <p class="check_registration__title">Регистрация</p>
        </b-col>

        <b-col cols="12" md="5">
          <div
            align-h="center"
            class="check_registration__status_line"
            :class="registration.reserve.status == 1 ? 'active' : 'no_active'"
          >
            <p class="check_registration__status_text">
              {{
                registration.reserve.status == 1 ? "Оплачена" : "Не оплачена"
              }}
            </p>
          </div>

          <b-row align-h="center">
            <p class="check_registration__text">
              <span class="subtitle_reg">Стоимость регистрации:</span>
              {{ registration.price.value }} ₽ <br />
              <span class="subtitle_reg">Количество мест:</span>
              {{ registration.price.seats }} <br />
              <span class="subtitle_reg">Для участников БК:</span>
              {{ registration.price.type_id == 2 ? "Да" : "Нет" }} <br />
            </p>
          </b-row>
        </b-col>
      </b-row>

      <!-- User -->
      <b-row align-h="center" class="check_registration__block">
        <b-col cols="12">
          <p class="check_registration__title">Пользователь</p>
        </b-col>

        <b-col cols="12" md="5">
          <b-row class="users__user_line">
            <b-col cols="2" md="2">
              <div class="users__user_img_wrapper">
                <img
                  v-if="registration.user.img"
                  class="users__user_img"
                  :src="
                    $store.state.storageUrl +
                    '/app/avatars/' +
                    registration.user.img
                  "
                />
                <img
                  v-else
                  class="users__user_img"
                  :src="$store.state.storageUrl + '/app/defaults/darkLogo.jpg'"
                />
              </div>
            </b-col>

            <b-col cols="10">
              <div class="users__user_info">
                <p class="users__user_name">
                  {{ registration.user.name }} {{ registration.user.last_name }}
                </p>
                <p
                  class="users__user_description"
                  v-html="registration.user.about_user"
                ></p>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- Event -->
      <b-row align-h="center" class="check_registration__block">
        <b-col cols="12">
          <p class="check_registration__title">Мероприятие</p>
        </b-col>

        <b-col cols="12" md="5">
          <EventCard :event="registration.event" />
        </b-col>
      </b-row>

      <!-- Pay -->
      <b-row> </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import EventCard from "@/components/events/EventCard.vue";

export default {
  name: "CheckRegistration",
  components: {
    EventCard,
  },
  data() {
    return {
      registration: null,
    };
  },
  methods: {
    async getRegistration() {
      const registrationID = this.$route.query.id;

      try {
        this.registration = await axios
          .get("check_registration/" + registrationID)
          .then((res) => {
            return res.data;
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.getRegistration();
  },
};
</script>

<style lang="scss">
.users__user_line {
  margin-bottom: 15px;
  background-color: $black;
  padding: 15px;
}

.users__user_img_wrapper {
  position: relative;
  height: 60px;
  width: 60px;
  overflow: hidden;
  border-radius: 100%;
  border: 2px solid $gray;
}
.users__user_img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.users__user_info {
  padding-left: 15px;
}
.users__user_name {
  font-size: 18px;
  font-weight: 600;
  color: $white;
  margin: 0;
}
.users__user_description {
  font-size: 16px;
  font-weight: 400;
  color: $light_gray__medium;
  margin: 0;
}
.users__user_score {
  font-size: 14px;
  font-weight: 700;
  color: $light_gray__medium;
  margin: 0;
}
.users__user_score.count_users {
  padding-bottom: 15px;
  font-weight: 600;
  font-size: 18px;
}

.check_registration__title {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: $accent;
}

.check_registration__block {
  margin-bottom: 30px;
}

.check_registration__status_line {
  padding: 15px 0;
}
.check_registration__status_line.active {
  background-color: green;
}
.check_registration__status_line.no_active {
  background-color: red;
}
.check_registration__status_text {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  text-align: center;
}
.check_registration__text {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  text-align: left;
}

.subtitle_reg {
  font-size: 16px;
  color: $light_gray__dark;
  font-weight: 400;
}
</style>
